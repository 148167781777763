import { Injectable, NgZone } from '@angular/core';
import { LAMPORTS_PER_SOL, PublicKey, Transaction } from '@solana/web3.js';
import { getAssociatedTokenAddressSync } from '@solana/spl-token';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  from,
  Observable,
  of,
  ReplaySubject,
  startWith,
  SubscriptionLike,
  switchMap,
  tap
} from 'rxjs';
import { delay } from 'rxjs/operators';
import {
  createAssociatedTokenAccountTransaction,
  createMintNeonTransaction,
  createUnwrapSOLTransaction,
  createWrapSOLTransaction,
  erc20Abi,
  neonBalanceProgramAddressV2,
  neonNeonTransaction,
  neonTransferMintTransaction,
  neonWrapper2Abi,
  solanaNEONTransferTransaction,
  solanaSOLTransferTransaction,
  SPLToken,
  toFullAmount,
  wrappedNeonTransaction
} from '@neonevm/token-transfer-core';
import { neonTransactionData } from '@neonevm/token-transfer-ethers';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Big } from 'big.js';
import { Interface, parseUnits, TransactionRequest, TransactionResponseParams, Wallet } from 'ethers';
import {
  claimTransactionData,
  createClaimInstructionSync,
  itemUnsubscribe,
  NEON,
  priorityFeeLamports,
  signerPrivateKey,
  SOL,
  solanaTransactionLog,
  timeout,
  TRANSACTION_FEE,
  useTransactionFromSignerEthers,
  W_NEON,
  W_SOL
} from '../../utils';
import {
  Address,
  Amount,
  PendingStatus,
  TransferDirection,
  TransferTokenFormData,
  TransferTokenLog,
  TransferTransaction,
  TransferTransactionConfirmation
} from '../../models';
import { HttpRpcClient } from '../../app/services';
import { environment } from '../../environments/environment';
import { ProxyStatusService } from './proxy-status.service';
import { SolanaWalletService } from './solana-wallet.service';
import { TransferTransactionService } from './transfer-transaction.service';
import { WalletConnectService } from './wallet-connect.service';
import { NeonChainService } from './neon-chain.service';
import { PriorityFeeService } from './priority-fee.service';
import { NeonTransferFeeService } from './neon-transfer-fee.service';
import { PythService } from './pyth.service';

@Injectable({ providedIn: 'root' })
export class TokenTransferService {
  formData$: ReplaySubject<TransferTokenFormData> = new ReplaySubject<TransferTokenFormData>(0);
  newAccountFee$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  pendingStatus$: BehaviorSubject<PendingStatus> = new BehaviorSubject<PendingStatus>(PendingStatus.default);
  transferData: TransferTokenFormData;
  private _transferLog$: ReplaySubject<TransferTokenLog> = new ReplaySubject<TransferTokenLog>(0);
  private _transferLog: TransferTokenLog = {};
  private subs: SubscriptionLike[] = [];
  private publicClient: any;

  get isSameNetwork(): boolean {
    return this.neon.chainId === this.proxy.chainId;
  }

  get isSolNetwork(): boolean {
    return this.proxy.gasToken$.value.tokenName === SOL;
  }

  get transferLog$(): Observable<TransferTokenLog> {
    return this._transferLog$.pipe(startWith(this._transferLog));
  }

  getGasPrice = async (transaction: TransactionRequest): Promise<{ gas: number, gasPrice: number }> => {
    const { from, to, data, value } = transaction;
    try {
      const gas = await this.neon.provider.estimateGas({ from, to, value, data });
      const feeGas = await this.neon.provider.getFeeData();
      // @deprecated
      // const gasPrice = await this.neon.provider.getGasPrice();
      return { gas: Number(gas), gasPrice: Number(feeGas.gasPrice) };
    } catch (e: any) {
      console.log(e?.message);
      return { gas: 25000, gasPrice: 235906041209 };
    }
  };

  _getGasPrice = async (transaction: TransactionRequest, url: string): Promise<{ gas: number, gasPrice: number }> => {
    const { from, to, data, value } = transaction;
    const gasPrice = await firstValueFrom(this.api.rpc(`eth_gasPrice`, [], url));
    const gas = await firstValueFrom(this.api.rpc(`eth_estimateGas`, [{ from, to, data, value }], url));
    return ({ gas: Number(gas), gasPrice: Number(gasPrice) });
  };

  transfer(data: TransferTokenFormData): Observable<TransferTransaction> {
    this.transferData = data;
    this.transferLog('data', data);
    this.ga.event(`token_transfer_${data?.token.token.name || ''}`, `${data?.direction}_transfer`, undefined, undefined, undefined, {
      neon_account: this.neon.address$.value,
      solana_account: this.solana.publicKey.toString(),
      direction: data?.direction || '',
      amount: data?.amount || 0,
      token: data?.token.token.name || '',
      priority_fee: data.priorityFee
    });
    return from(this.transferMethod(data));
  }

  async transferMethod(data: TransferTokenFormData): Promise<TransferTransaction> {
    const { direction, amount, rewardFrom, token: { token } } = data;
    const priorityFee = this.fee.selected$.value.amount; // micro-lamports
    const neonDirection = direction === TransferDirection.neon;
    switch (token.symbol) {
      case NEON: {
        if (neonDirection) {
          return this.transferNEONToSolana(amount, token);
        } else if (rewardFrom === 'neon') {
          const units = this.fee.data.units; // micro-lamports
          const pFee = priorityFeeLamports(priorityFee, units).add(TRANSACTION_FEE).round();
          const solPerNeon = this.pyth.solPerNeon;
          const neon = this.pyth.neonPrice;
          const rewardAmountAndFee = pFee.times(solPerNeon).times(neon).round();
          const sendAmount = new Big(amount.toString()).times(LAMPORTS_PER_SOL).minus(rewardAmountAndFee).div(LAMPORTS_PER_SOL);
          return this.transferNEONWithNeonFeeToNeon(sendAmount.toString(), rewardAmountAndFee.toString(), token, priorityFee);
        }
        return this.transferNEONToNeon(amount, token, priorityFee);
      }
      case W_NEON:
        return this.transferWNEONToSolana(amount, token);
      case SOL:
      // if (this.isSolNetwork) {
      //   if (neonDirection) {
      //     return this.transferSOLToSolana(amount, token);
      //   }
      //   return this.transferSOLToNeon(amount, token);
      // }
      // return this.transferWSOLToNeon(amount, token, priorityFee);
      case W_SOL:
        return (neonDirection ? this.transferWSOLToSolana : this.transferERC20ToNeon)(amount, token, priorityFee);
    }
    return (neonDirection ? this.transferERC20ToSolana : this.transferERC20ToNeon)(amount, token, priorityFee);
  }

  transferNEONToNeonTransaction = (amount: Amount, token: SPLToken): Promise<Transaction> => {
    this.transferLog('method', 'transferNEONToNeonTransaction');
    const method = (a: Amount, t: SPLToken) => {
      return solanaNEONTransferTransaction(this.solana.publicKey, this.neon.address, this.proxy.programId, this.proxy.tokenMint, t, a, this.proxy.chainId);
    };
    return this.transaction.solanaTransaction(amount, token, method);
  };

  transferNEONToNeonWithNeonFeeTransaction = (amount: Amount, token: SPLToken, serviceWallet: PublicKey, rewardAmount: Amount, priorityFee: number): Promise<Transaction> => {
    const method = async (a: Amount, t: SPLToken) => {
      let transaction = await solanaNEONTransferTransaction(this.solana.publicKey, this.neon.address, this.proxy.programId, this.proxy.tokenMint, t, a, this.proxy.chainId, serviceWallet, rewardAmount);
      transaction = await this.fee.transactionPriorityFee(transaction, priorityFee);
      return transaction;
    };
    return this.transaction.solanaTransaction(amount, token, method);
  };

  transferNEONToSolanaTransaction = (amount: Amount, token: SPLToken, contract: `0x${string}`): Promise<TransactionRequest> => {
    this.transferLog('method', 'transferNEONToSolanaTransaction');
    const method = async (a: Amount) => {
      const data = neonTransactionData(this.solana.publicKey);
      return neonNeonTransaction<TransactionRequest>(this.neon.address, contract, a, data);
    };
    return this.transaction.neonTransaction(amount, token, method);
  };

  transferSOLToNeonTransaction = (amount: Amount, token: SPLToken): Promise<Transaction> => {
    this.transferLog('method', 'transferSOLToNeonTransaction');
    const method = async (a: Amount, t: SPLToken) => {
      return await solanaSOLTransferTransaction(this.solana.connection, this.solana.publicKey, this.neon.address, this.proxy.programId, this.proxy.tokenMint, t, a, this.proxy.chainId);
    };
    return this.transaction.solanaTransaction(amount, token, method);
  };

  transferERC20ToNeonTransaction = (amount: Amount, token: SPLToken): Promise<Transaction> => {
    this.transferLog('method', 'transferERC20ToNeonTransaction');
    const method = async (a: Amount, t: SPLToken) => {
      const fullAmount = toFullAmount(a, token.decimals);
      const associatedToken = getAssociatedTokenAddressSync(new PublicKey(t.address_spl), this.solana.publicKey);
      const climeData = claimTransactionData(associatedToken, this.neon.address, fullAmount);
      const walletSigner = new Wallet(signerPrivateKey(this.solana.publicKey, this.neon.address), this.neon.provider);
      const signedTransaction = await useTransactionFromSignerEthers(climeData, walletSigner, t.address);
      const neonEmulate = await firstValueFrom(this.proxy.neonEmulate([signedTransaction.rawTransaction!.slice(2)]));
      const { neonKeys, legacyAccounts } = createClaimInstructionSync(neonEmulate);
      return neonTransferMintTransaction(this.solana.connection, this.proxy.programId, this.solana.publicKey, this.neon.address, walletSigner, neonKeys, legacyAccounts, signedTransaction, t, fullAmount, this.proxy.chainId);
    };
    return this.transaction.solanaTransaction(amount, token, method);
  };

  transferWSOLToNeonTransaction = (amount: Amount, token: SPLToken): Promise<Transaction> => {
    this.transferLog('method', 'transferWSOLToNeonTransaction');
    const method = async (a: Amount, t: SPLToken) => {
      const associatedToken = getAssociatedTokenAddressSync(new PublicKey(t.address_spl), this.solana.publicKey);
      const fullAmount = toFullAmount(a, t.decimals);
      const climeData = claimTransactionData(associatedToken, this.neon.address, fullAmount);
      const walletSigner = new Wallet(signerPrivateKey(this.solana.publicKey, this.neon.address), this.neon.provider);
      const neonSignedTransaction = await useTransactionFromSignerEthers(climeData, walletSigner, t.address);
      const neonEmulate = await firstValueFrom(this.proxy.neonEmulate([neonSignedTransaction.rawTransaction!.slice(2)]));
      const { neonKeys, legacyAccounts } = createClaimInstructionSync(neonEmulate);
      const transaction = await createWrapSOLTransaction(this.solana.connection, this.solana.publicKey, a, t);
      const mintTransaction = await neonTransferMintTransaction(this.solana.connection, this.proxy.programId, this.solana.publicKey, this.neon.address, walletSigner, neonKeys, legacyAccounts, neonSignedTransaction, t, fullAmount, this.proxy.chainId);
      transaction.add(...mintTransaction.instructions);
      return transaction;
    };
    return this.transaction.solanaTransaction(amount, token, method);
  };

  transferERC20ToSolanaSolanaTransaction = async (amount: Amount, token: SPLToken): Promise<Transaction> => {
    this.transferLog('method', 'transferERC20ToSolanaSolanaTransaction');
    const method = async (a: Amount, t: SPLToken) => {
      const mintPubkey = new PublicKey(t.address_spl);
      const publicKey = this.solana.publicKey;
      const associatedToken = getAssociatedTokenAddressSync(mintPubkey, publicKey);
      const account = await this.solana.connection.getAccountInfo(associatedToken, 'confirmed');
      if (!account) {
        return createAssociatedTokenAccountTransaction(publicKey, mintPubkey, associatedToken);
      }
      return new Transaction();
    };
    return this.transaction.solanaTransaction(amount, token, method);
  };

  transferERC20ToSolanaNeonTransaction = async (amount: Amount, token: SPLToken): Promise<TransactionRequest> => {
    this.transferLog('method', 'transferERC20ToSolanaNeonTransaction');
    const method = async (a: Amount, t: SPLToken) => {
      const associatedToken = getAssociatedTokenAddressSync(new PublicKey(t.address_spl), this.solana.publicKey);
      const tokenContract = new Interface(erc20Abi);
      const fullAmount = parseUnits(a.toString(), token.decimals);
      const data = tokenContract.encodeFunctionData('transferSolana', [associatedToken.toBuffer(), fullAmount]);
      return createMintNeonTransaction<TransactionRequest>(this.neon.address, t, data);
    };
    return this.transaction.neonTransaction(amount, token, method);
  };

  unwrapWNEONInNeonTransaction = async (amount: Amount, token: SPLToken): Promise<TransactionRequest> => {
    this.transferLog('method', 'unwrapWNEONInNeonTransaction');
    const method = async (a: Amount, t: SPLToken) => {
      const tokenContract = new Interface(neonWrapper2Abi);
      const fullAmount = parseUnits(a.toString(), token.decimals);
      const data = tokenContract.encodeFunctionData('withdraw', [fullAmount]);
      return wrappedNeonTransaction<TransactionResponseParams>(this.neon.address, t.address, data);
    };
    return this.transaction.wNeonTransaction(amount, token, method);
  };

  wrapSOLInSolanaTransaction = async (amount: Amount, token: SPLToken): Promise<Transaction> => {
    this.transferLog('method', 'wrapSOLInSolanaTransaction');
    const method = async (a: Amount, t: SPLToken) => {
      return createWrapSOLTransaction(this.solana.connection, this.solana.publicKey, a, t);
    };
    return this.transaction.solanaTransaction(amount, token, method);
  };

  unwrapSOLInSolanaTransaction = async (amount: Amount, token: SPLToken): Promise<Transaction> => {
    this.transferLog('method', 'unwrapSOLInSolanaTransaction');
    const method = async (a: Amount, t: SPLToken) => {
      return createUnwrapSOLTransaction(this.solana.connection, this.solana.publicKey, t);
    };
    return this.transaction.solanaTransaction(amount, token, method);
  };

  // for transfer NEON (Solana -> Neon) with Neon fee
  transferNEONWithNeonFeeToNeon = async (amount: Amount, rewardAmount: Amount, token: SPLToken, priorityFee: number): Promise<TransferTransaction> => {
    return this.tryTransfer(async () => {
      const serviceWallet = this.neonFee.serviceWallet$.value!;
      const transaction = await this.transferNEONToNeonWithNeonFeeTransaction(amount, token, serviceWallet, rewardAmount, priorityFee);
      transaction.feePayer = serviceWallet;
      solanaTransactionLog(transaction);
      this.pendingStatus$.next(PendingStatus.signature);
      const signedTransaction = await this.zone.run(() => this.solana.provider.signTransaction(transaction));
      this.pendingStatus$.next(PendingStatus.signed);
      const [, walletSign] = signedTransaction.signatures;
      const serializedTx = signedTransaction.serialize({ verifySignatures: false });
      const data = Buffer.from(serializedTx).toString('hex');
      const signature = Buffer.from(walletSign.signature!).toString('hex');
      const response = await firstValueFrom(this.neonFee.sendTransaction({ data, signature }));
      const solana = { transaction: signedTransaction, signature: response?.signature };
      this.transaction.solanaClean();
      this.transferLog('transaction', { solana });
      return { solana };
    });
  };

  // for transfer NEON (Solana -> Neon) with Solana fee
  transferNEONToNeon = async (amount: Amount, token: SPLToken, priorityFee: number): Promise<TransferTransaction> => {
    return this.tryTransfer(async () => {
      let transaction = await this.transferNEONToNeonTransaction(amount, token);
      transaction = await this.fee.transactionPriorityFee(transaction, priorityFee);
      this.pendingStatus$.next(PendingStatus.signature);
      const signedTransaction = await this.zone.run(() => this.solana.provider.signTransaction(transaction));
      this.pendingStatus$.next(PendingStatus.signed);
      const signature = await this.solana.connection.sendRawTransaction(signedTransaction.serialize());
      const solana = { transaction: signedTransaction, signature };
      this.transaction.solanaClean();
      this.transferLog('transaction', { solana });
      return { solana };
    });
  };

  transferNEONToSolana = async (amount: Amount, token: SPLToken, skipStart = false): Promise<TransferTransaction> => {
    return this.tryTransfer(async () => {
      const transaction = await this.transferNEONToSolanaTransaction(amount, token, environment.neon.token_contract as Address);
      this.pendingStatus$.next(PendingStatus.signature);
      const signature = await this.neon.getTransactionReceipt(transaction);
      this.pendingStatus$.next(PendingStatus.signed);
      const neon = { transaction, signature };
      this.transferLog('transaction', { neon });
      this.transaction.neonClean();
      return { neon };
    }, skipStart);
  };

  transferSOLToSolana = async (amount: Amount, token: SPLToken): Promise<TransferTransaction> => {
    return this.tryTransfer(async () => {
      const transaction = await this.transferNEONToSolanaTransaction(amount, token, environment.neon.token_contract_sol as Address);
      this.pendingStatus$.next(PendingStatus.signature);
      const signature = await this.neon.getTransactionReceipt(transaction);
      this.pendingStatus$.next(PendingStatus.signed);
      const neon = { transaction, signature };
      this.transferLog('transaction', { neon });
      this.transaction.neonClean();
      return { neon };
    });
  };

  transferSOLToNeon = async (amount: Amount, token: SPLToken): Promise<TransferTransaction> => {
    return this.tryTransfer(async () => {
      const transaction = await this.transferSOLToNeonTransaction(amount, token);
      this.pendingStatus$.next(PendingStatus.signature);
      const signedTransaction = await this.solana.provider.signTransaction(transaction);
      this.pendingStatus$.next(PendingStatus.signed);
      const signature = await this.solana.connection.sendRawTransaction(signedTransaction.serialize(), { skipPreflight: false });
      const solana = { transaction: signedTransaction, signature };
      this.transferLog('transaction', { solana });
      this.transaction.neonClean();
      return { solana };
    });
  };

  transferERC20ToNeon = async (amount: Amount, token: SPLToken, priorityFee: number): Promise<TransferTransaction> => {
    return this.tryTransfer(async () => {
      let transaction = await this.transferERC20ToNeonTransaction(amount, token);
      transaction = await this.fee.transactionPriorityFee(transaction, priorityFee);
      this.pendingStatus$.next(PendingStatus.signature);
      const signedTransaction = await this.solana.provider.signTransaction(transaction);
      this.pendingStatus$.next(PendingStatus.signed);
      const signature = await this.solana.connection.sendRawTransaction(signedTransaction.serialize(), { skipPreflight: false });
      const solana = { transaction: signedTransaction, signature };
      this.transferLog('transaction', { solana });
      this.transaction.solanaClean();
      return { solana };
    });
  };

  transferERC20ToSolana = async (amount: Amount, token: SPLToken): Promise<TransferTransaction> => {
    return this.tryTransfer(async () => {
      let solana;
      const solanaTransaction = await this.transferERC20ToSolanaSolanaTransaction(amount, token);
      if (solanaTransaction.instructions.length > 0) {
        this.pendingStatus$.next(PendingStatus.signature);
        const solanaSignTransaction = await this.solana.provider.signTransaction(solanaTransaction);
        const solanaSendTransaction = await this.solana.connection.sendRawTransaction(solanaSignTransaction.serialize(), { skipPreflight: false });
        solana = { transaction: solanaSignTransaction, signature: solanaSendTransaction };
        await this.confirmTransaction({ solana });
      }
      const neonTransaction = await this.transferERC20ToSolanaNeonTransaction(amount, token);
      this.pendingStatus$.next(PendingStatus.signature);
      const neonSignedTransaction = await this.neon.getTransactionReceipt(neonTransaction);
      this.pendingStatus$.next(PendingStatus.signed);
      const neon = { transaction: neonTransaction, signature: neonSignedTransaction };
      this.transferLog('transaction', { solana, neon });
      this.transaction.clean();
      return { neon, solana };
    });
  };

  transferWNEONToSolana = async (amount: Amount, token: SPLToken): Promise<TransferTransaction> => {
    await this.unwrapWNEONInNeon(amount, token);
    return this.transferNEONToSolana(amount, token, true);
  };

  transferWSOLToNeon = async (amount: Amount, token: SPLToken, priorityFee: number): Promise<TransferTransaction> => {
    return this.tryTransfer(async () => {
      let transaction = await this.transferWSOLToNeonTransaction(amount, token);
      transaction = await this.fee.transactionPriorityFee(transaction, priorityFee);
      this.pendingStatus$.next(PendingStatus.signature);
      const signedTransaction = await this.solana.provider.signTransaction(transaction);
      this.pendingStatus$.next(PendingStatus.signed);
      const signature = await this.solana.connection.sendRawTransaction(signedTransaction.serialize(), { skipPreflight: false });
      const solana = { transaction, signature };
      this.transferLog('transaction', { solana });
      this.transaction.solanaClean();
      return { solana };
    });
  };

  transferWSOLToSolana = async (amount: Amount, token: SPLToken): Promise<TransferTransaction> => {
    await this.transferERC20ToSolana(amount, token);
    return this.unwrapSOLInSolana(amount, token);
  };

  unwrapWNEONInNeon = async (amount: Amount, token: SPLToken): Promise<TransferTransaction> => {
    return this.tryTransfer(async () => {
      const transaction = await this.unwrapWNEONInNeonTransaction(amount, token);
      this.pendingStatus$.next(PendingStatus.signature);
      const signature = await this.neon.getTransactionReceipt(transaction);
      const neon = { transaction, signature };
      this.transaction.clean();
      return { neon };
    });
  };

  wrapSOLInSolana = (amount: Amount, token: SPLToken): Promise<TransferTransaction> => {
    return this.tryTransfer(async () => {
      const transaction = await this.wrapSOLInSolanaTransaction(amount, token);
      this.pendingStatus$.next(PendingStatus.signature);
      const signedTransaction = await this.zone.run(() => this.solana.provider.signTransaction(transaction));
      const signature = await this.solana.connection.sendRawTransaction(signedTransaction.serialize(), { skipPreflight: false });
      const solana = { transaction: signedTransaction, signature };
      this.transaction.solanaClean();
      return { solana };
    });
  };

  unwrapSOLInSolana = async (amount: Amount, token: SPLToken): Promise<TransferTransaction> => {
    const transaction = await this.unwrapSOLInSolanaTransaction(amount, token);
    const signedTransaction = await this.zone.run(() => this.solana.provider.signTransaction(transaction));
    const signature = await this.solana.connection.sendRawTransaction(signedTransaction.serialize(), { skipPreflight: false });
    const solana = { transaction: signedTransaction, signature };
    this.transaction.solanaClean();
    return { solana };
  };

  confirmTransaction = (data: TransferTransaction): Promise<TransferTransactionConfirmation> => {
    return new Promise(async (resolve, reject) => {
      try {
        const result: TransferTransactionConfirmation = {};
        if (data.solana?.signature) {
          const { lastValidBlockHeight, blockhash } = await this.solana.connection.getLatestBlockhash();
          const response = await this.solana.connection.confirmTransaction({
            blockhash,
            lastValidBlockHeight,
            signature: data.solana.signature
          });
          result.solana = response.value;
        }
        resolve(result);
      } catch (e) {
        reject(e);
      }
    });
  };

  transferLog(key: keyof TransferTokenLog, size: any): void {
    this._transferLog[key] = size;
    this._transferLog$.next(this._transferLog);
  }

  clearTransferLog(): void {
    this._transferLog = {};
    this._transferLog$.next(this._transferLog);
    this.pendingStatus$.next(PendingStatus.started);
  }

  provideNewAccountFee(address: string, publicKey: PublicKey): Observable<any> {
    if (address && publicKey) {
      const [neonBalanceAccount] = neonBalanceProgramAddressV2(address, publicKey, this.proxy.programId, this.proxy.chainId);
      return from(this.solana.connection.getAccountInfo(neonBalanceAccount)).pipe(tap(account => {
        this.newAccountFee$.next(!account);
      }));
    }
    return of(null).pipe(delay(100));
  }

  airdropClose(): void {
    this.newAccountFee$.next(false);
  }

  init(): void {
    this.subs.push(combineLatest([this.proxy.proxyStatus$, this.neon.address$, this.solana.publicKey$]).pipe(
      tap(([, neonWalletAddress, solanaWalletAddress]) => {
        if (!!neonWalletAddress && !!solanaWalletAddress) {
          this.publicClient = this.neon.publicClient; //Access viem's public client
        }
      }),
      switchMap(([_, n, s]) => this.provideNewAccountFee(n, s))).subscribe());
    this.pendingStatus$.next(PendingStatus.confirmed);
  }

  destroy(): void {
    itemUnsubscribe(this.subs);
    this.pendingStatus$.complete();
  }

  private tryTransfer = (fn: () => any, skipStart = false): Promise<TransferTransaction> => {
    return new Promise(async (resolve, reject) => {
      if (!skipStart) {
        this.pendingStatus$.next(PendingStatus.started);
        await timeout(1000);
      }
      try {
        if (typeof fn === 'function') {
          if (!this.isSameNetwork) {
            await firstValueFrom(this.chain.chainSwitch());
            console.time('Chain switch emmit');
            await timeout(4e3);
            console.time('Timeout emmit');
          }
          const result = await fn();
          console.time('Result emit');
          resolve(result);
        }
      } catch (e) {
        reject(e);
        this.transferLog('error', e as Error);
        this.transaction.clean();
      }
    });
  };

  constructor(public solana: SolanaWalletService, public neon: WalletConnectService, public proxy: ProxyStatusService,
              private neonFee: NeonTransferFeeService, private fee: PriorityFeeService, private ga: GoogleAnalyticsService,
              private chain: NeonChainService, private transaction: TransferTransactionService, private api: HttpRpcClient,
              private pyth: PythService, private zone: NgZone) {
  }
}
