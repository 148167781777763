import { Injectable } from '@angular/core';
import { PublicKey } from '@solana/web3.js';
import { getAssociatedTokenAddressSync } from '@solana/spl-token';
import { SPLToken } from '@neonevm/token-transfer-core';
import { throttleTime } from 'rxjs/operators';
import {
  catchError,
  combineLatest,
  debounceTime,
  delay,
  filter,
  forkJoin,
  from,
  map,
  Observable,
  of,
  ReplaySubject,
  SubscriptionLike,
  switchMap,
  tap
} from 'rxjs';
import { Address, TransferDirection, TransferToken } from '../../models';
import {
  $WIF,
  BLZE,
  Bonk,
  CGT,
  CHAIN_ID,
  itemUnsubscribe,
  JITO,
  JitoSOL,
  JSOL,
  JUP,
  LST,
  MELL,
  MNGO,
  mSOL,
  NEON,
  NEON_TOKEN_MODEL,
  POPCAT,
  PYTH,
  SOL,
  SOL_TOKEN_MODEL,
  T2080,
  USDC,
  USDT,
  W_BTC,
  W_ETH,
  W_NEON,
  W_SOL,
  WEN,
  WHALE
} from '../../utils';
import { SolanaWalletService } from './solana-wallet.service';
import { WalletConnectService } from './wallet-connect.service';
import { ProxyStatusService } from './proxy-status.service';

@Injectable({ providedIn: 'root' })
export class TokensListService {
  chainId = CHAIN_ID;
  NEON: SPLToken;
  SOL: SPLToken;
  wSOL: SPLToken;
  private _tokens: TransferToken[] = [];
  private _tokens$: ReplaySubject<TransferToken[]> = new ReplaySubject<TransferToken[]>(0);
  private _supportTokens = [
    NEON, W_NEON, SOL, W_SOL, USDC, USDT, W_ETH,
    JitoSOL, JUP, PYTH, Bonk, W_BTC, LST,
    JITO, mSOL, MELL, MNGO, T2080,
    BLZE, JSOL, CGT, $WIF, WEN, POPCAT, WHALE
  ];
  private _supportTokensNeon = [
    NEON, W_NEON, SOL, W_SOL, USDC, USDT, W_ETH,
    JitoSOL, JUP, PYTH, Bonk, W_BTC, LST,
    JITO, mSOL, MELL, MNGO, T2080,
    BLZE, JSOL, CGT, $WIF, WEN, POPCAT, WHALE
  ];
  private _supportTokensSol = [
    SOL, W_SOL, USDT, USDC, W_ETH, LST,
    JitoSOL, mSOL, Bonk, MELL, MNGO, T2080, PYTH, W_BTC
  ];
  private sub: SubscriptionLike[] = [];

  get tokens$(): Observable<TransferToken[]> {
    return this._tokens$;
  }

  neonTokensBalance(): Observable<TransferToken[]> {
    return combineLatest([this.tokens$, this.neon.address$]).pipe(
      filter(([tokens]) => tokens.length > 0),
      switchMap(([tokens, address]) => {
        if (address?.length > 0) {
          const requests = tokens.map(token => this.mintBalance(token, address));
          return forkJoin(requests);
        }
        tokens.map(token => token.neonReset());
        return of(tokens).pipe(delay(100));
      }));
  }

  solanaTokensBalance(): Observable<TransferToken[]> {
    return combineLatest([this.tokens$, this.solana.publicKey$]).pipe(
      debounceTime(100),
      filter(([tokens]) => tokens.length > 0),
      switchMap(([tokens, address]) => {
        if (address instanceof PublicKey) {
          const requests = tokens.map(token => this.splBalance(token, address));
          return forkJoin(requests);
        }
        tokens.map(token => token.solanaReset());
        return of(tokens).pipe(delay(100));
      }));
  }

  mintBalance(token: TransferToken, address: Address): Observable<TransferToken> {
    let methodForNativeToken: Observable<unknown>;
    let method: Observable<unknown>;
    let native = false;
    switch (token.token.symbol) {
      case NEON: {
        native = true;
        methodForNativeToken = this.neon.neonBalance();
        break;
      }
      case W_NEON: {
        method = from(this.neon.wNeonBalance(token.token));
        break;
      }
      default: {
        if (token.token.symbol === SOL && this.proxy.gasToken$.value?.tokenName === SOL) {
          native = true;
          methodForNativeToken = this.neon.neonBalance();
          break;
        } else {
          method = from(this.neon.tokenBalance(token.token));
        }
      }
    }

    // @ts-ignore
    const callableMethod = methodForNativeToken || method;
    return callableMethod.pipe(map((d: any) => {
        const decimals = native ? d.decimals : token.token.decimals;
        token.neonUpdate({ amount: BigInt(d.value), decimals });
        return token;
      }),
      catchError(() => {
        token.neonUpdate({ amount: 0n, decimals: token.token.decimals });
        return of(token);
      }));
  }

  splBalance(token: TransferToken, pubkey: PublicKey): Observable<TransferToken> {
    let method: Observable<unknown>;
    if (token.token.symbol === SOL) {
      method = from(this.solana.connection.getBalance(pubkey))
        .pipe(map(d => ({ value: { amount: d, decimals: 9 } })));
    } else if (token.token.address_spl) {
      const mintAccount = new PublicKey(token.token.address_spl);
      const tokenAddress = getAssociatedTokenAddressSync(mintAccount, pubkey);
      method = from(this.solana.connection.getTokenAccountBalance(tokenAddress));
    } else {
      method = of({ value: { amount: 0, decimals: token.token.decimals } });
    }
    return method.pipe(map((d) => {
      // @ts-ignore
      token.solanaUpdate({ amount: BigInt(d.value.amount), decimals: d.value.decimals });
      return token;
    }), catchError(_ => {
      token.solanaUpdate({ amount: 0n, decimals: token.token.decimals });
      return of(token);
    }));
  }

  tokenBalance(token: TransferToken): Observable<TransferToken> {
    return combineLatest([this.neon.address$, this.solana.publicKey$]).pipe(
      switchMap(([n, s]) => {
        const result = [];
        if (n) {
          switch (token.token.symbol) {
            case W_NEON:
              this.findTokenBalance(NEON, (t) => result.push(this.mintBalance(t, n)));
              break;
            default:
              result.push(this.mintBalance(token, n));
              break;
          }
        }
        if (s) {
          switch (token.token.symbol) {
            case SOL:
              this.findTokenBalance(W_SOL, (t) => result.push(this.splBalance(t, s)));
              break;
            case W_NEON:
              this.findTokenBalance(NEON, (t) => result.push(this.splBalance(t, s)));
              break;
            default:
              result.push(this.splBalance(token, s));
              break;
          }
        }
        return forkJoin(result);
      }),
      map(() => token));
  }

  init(): void {
    this.sub.push(this.neon.refresh$.pipe(debounceTime(1500), switchMap(() => this.neonTokensBalance())).subscribe());
    this.sub.push(this.solana.refresh$.pipe(throttleTime(1500), switchMap(() => this.solanaTokensBalance())).subscribe());
    this.sub.push(this.proxy.gasToken$.pipe(tap(d => {
      let supported = this._supportTokens;
      switch (d.tokenName) {
        case SOL:
          supported = this._supportTokensSol;
          break;
        case NEON:
          supported = this._supportTokensNeon;
          break;
      }
      const tokensList = this.supportTokens(this._tokens, supported).map(t => {
        t.token.chainId = parseInt(d.tokenChainId);
        return t;
      });
      this._tokens$.next(tokensList);
    })).subscribe());
  }

  destroy(): void {
    itemUnsubscribe(this.sub);
  }

  tokensOrderByBalance = (tokens: TransferToken[], direction: TransferDirection): TransferToken[] => {
    const b: TransferToken[] = [];
    const e: TransferToken[] = [];
    for (const token of tokens) {
      if (token[direction].value.balance?.amount) {
        b.push(token);
      } else {
        e.push(token);
      }
    }
    return b.concat(e);
  };

  private findTokenBalance = (symbol: string, method: (token: TransferToken) => void): void => {
    const id = this._tokens.findIndex(i => i.token.symbol === symbol);
    if (id > -1) {
      method(this._tokens[id]);
    }
  };

  private supportTokens = (items: TransferToken[], supported: string[] = this._supportTokens): TransferToken[] => {
    const result: TransferToken[] = [];
    for (const token of supported) {
      const id = items.findIndex(i => i.token.symbol === token);
      if (id > -1) {
        result.push(items[id]);
      }
    }
    return result;
  };

  constructor(private neon: WalletConnectService, private solana: SolanaWalletService, private proxy: ProxyStatusService) {
    this.NEON = { ...NEON_TOKEN_MODEL, chainId: this.chainId };
    import('token-list/tokenlist.json').then((tokenList) => {
      this._tokens = (tokenList?.tokens as SPLToken[] ?? [])
        .filter(t => t.chainId === this.chainId)
        .map(t => new TransferToken(t));
      const id = this._tokens.findIndex(i => i.token.symbol === W_SOL);
      if (id > -1) {
        this.SOL = { ...this._tokens[id].token, ...SOL_TOKEN_MODEL, chainId: this.chainId };
        this._tokens.unshift(new TransferToken(this.NEON), new TransferToken(this.SOL));
      } else {
        this._tokens.unshift(new TransferToken(this.NEON));
      }
      this._tokens$.next(this.supportTokens(this._tokens));
    });
  }
}
